<template>
    <div>
        <div>
            <Breadcrumb :model='breadcrumbItem'>
                <template #item='{item}'>
                    <a @click='backFolder(item)'>{{ item.name }}</a>
                </template>
            </Breadcrumb>
        </div>
        <div class='flex flex-wrap'>
            <div class='flex flex-column align-items-center folderItem ml-4'
                 @click="showMode === 'folder' ?enterFolder(folder):openFile(folder)"
                 v-for='folder in folderList' :key='folder'>
                <Image v-if="showMode === 'folder'" :src="'layout/images/'+(showMode==='folder'?'folder':'pdf')+'.svg'"
                       alt='文件夹' />
                <Image v-else class='mt-3' width='100'
                       :src="'layout/images/'+(showMode==='folder'?'folder':'pdf')+'.svg'" alt='文件夹' />
                <span style='width:128px;text-align:center'>
                    {{ showMode === 'folder' ? folder.name : folder.gmtCreate }}
                </span>
                <div class='mt-2 downloadDiv'>
                    <Button :label="showMode === 'folder' ? '打包下载' : '下载文件'" icon='pi pi-download'
                            @click.stop='downloadItem(folder)'
                            class='p-button-info p-button-text'></Button>
                </div>
            </div>
            <span v-if='folderList.length === 0' class='m-5'>暂无数据！</span>
        </div>
        <Sidebar v-model:visible='visibleLeft' class='p-sidebar-lg' style='border-radius: 0;width:50%'>
            <div>
                <LXXJ v-if="currentRecord.formId === 'LXXJ'" :processItem='currentRecord'></LXXJ>
                <HCJZ v-if="currentRecord.formId === 'HCJZ'" :processItem='currentRecord'></HCJZ>
                <WXJL v-if="currentRecord.formId === 'WXJL'" :processItem='currentRecord'></WXJL>
                <YHPGH v-if="currentRecord.formId === 'YHPGH'" :processItem='currentRecord'></YHPGH>
                <BYGH v-if="currentRecord.formId === 'BYGH'" :processItem='currentRecord'></BYGH>
                <SYBD v-if="currentRecord.formId === 'SYDB'" :processItem='currentRecord'></SYBD>
                <GZBG v-if="currentRecord.formId === 'GZBG'" :processItem='currentRecord'></GZBG>
                <DSFDB v-if="currentRecord.formId === 'DSFDB'" :processItem='currentRecord'></DSFDB>
                <MYKH v-if="currentRecord.formId === 'MYKH'" :processItem='currentRecord'></MYKH>
            </div>
        </Sidebar>
        <Dialog position='center' v-model:visible='downloadDialog' header='下载文件' :closable='false' :modal='true'>
            请稍等, 请求处理中......
            <template #footer />
        </Dialog>
    </div>
</template>

<script>
import SiteAnalyzeService from '../service/SiteAnalyzeService';
import LXXJ from './digitalRecords/LXXJ';
import HCJZ from './digitalRecords/HCJZ';
import WXJL from './digitalRecords/WXJL';
import YHPGH from './digitalRecords/YHPGH';
import BYGH from './digitalRecords/BYGH';
import SYBD from './digitalRecords/SYBD';
import GZBG from './digitalRecords/GZBG';
import DSFDB from './digitalRecords/DSFDB';
import MYKH from './digitalRecords/MYKH';
import dayjs from 'dayjs';

export default {
    components: {
        LXXJ,
        HCJZ,
        WXJL,
        YHPGH,
        BYGH,
        SYBD,
        GZBG,
        DSFDB,
        MYKH,
    },
    emits: ['show-pdf-report'],
    data() {
        return {
            visibleLeft: false,
            recordList: [],
            home: { icon: 'pi pi-home' },
            breadcrumbItem: [{ key: 'ROOT', level: 1, type: '', name: '根目录' }],
            showMode: 'folder',
            folderList: [],
            rootFolderList: [
                { key: 'LXXJ', level: 2, type: 'KEY', name: '例行巡检记录' },
                { key: 'HCJZ', level: 2, type: 'KEY', name: '标样核查及校准结果记录' },
                { key: 'WXJL', level: 2, type: 'KEY', name: '维修记录' },
                { key: 'YHPGH', level: 2, type: 'KEY', name: '易耗品更换记录' },
                { key: 'BYGH', level: 2, type: 'KEY', name: '标准样品更换记录' },
                { key: 'SYDB', level: 2, type: 'KEY', name: '实际水样对比试验结果记录' },
                { key: 'GZBG', level: 2, type: 'KEY', name: '在线监测设备异常说明' },
            ],
            currentRecord: {},
        };
    },
    siteAnalyzeService: null,
    created() {
        this.siteAnalyzeService = new SiteAnalyzeService();
    },
    mounted() {
        this.folderList = this.rootFolderList;
        this.loadRecords();
    },
    methods: {
        loadRecords() {
            const siteId = this.$store.getters.currentSelectedSite.id;
            this.siteAnalyzeService.siteAllRecord({ 'siteId': siteId }).then((res) => {
                if (res.data.success) {
                    this.recordList = res.data.data;
                }
            });
        },
        enterFolder(folder) {
            this.breadcrumbItem.push(folder);
            this.handleFolder(folder);
        },
        openFile(file) {
            // console.log(file);
            this.currentRecord = file;
            this.visibleLeft = true;
        },
        handleFolder(folder) {
            this.folderList = [];
            this.showMode = 'folder';
            if (folder.key === 'ROOT') {
                this.folderList = this.rootFolderList;
                this.breadcrumbItem = [{ key: 'ROOT', level: 1, type: '', name: '根目录' }];
            } else if (folder.type === 'KEY') {
                if (folder.level === 2) {
                    const dataArray = this.recordList.filter((r) => r.formId === folder.key);
                    const tempKeys = [];
                    dataArray.forEach((d) => {
                        const month = d.gmtCreate.substring(0, 7);
                        if (tempKeys.indexOf(month) === -1) {
                            tempKeys.push(month);
                            this.folderList.push(
                                { key: folder.key, level: 3, type: folder.type, name: month },
                            );
                        }
                    });
                } else if (folder.level === 3) {
                    this.folderList = this.recordList
                        .filter((r) => r.formId === folder.key)
                        .filter((r) => r.gmtCreate.indexOf(folder.name) > -1);
                    // console.log(this.folderList);
                    this.showMode = 'file';
                }
            }
        },
        backFolder(item) {
            if (this.breadcrumbItem[this.breadcrumbItem.length - 1].name === item.name) {
                // 点击自己，忽略
                return;
            }
            this.breadcrumbItem.pop();
            const currentFolder = this.breadcrumbItem[this.breadcrumbItem.length - 1];
            this.handleFolder(currentFolder);
        },
        downloadItem(item) {
            console.log(item);
            const siteId = this.$store.getters.currentSelectedSite.id;
            const paramsObj = {
                'siteId': siteId,
                'key': item.formId ?? item.key,
            };
            if (item.level === 3) {
                const month = dayjs(item.name, 'YYYY-MM');
                paramsObj.startTs = month.startOf('month').valueOf();
                paramsObj.endTs = month.endOf('month').valueOf();
            }
            if (item.id) {
                paramsObj.recordId = item.id;
            }
            this.downloadDialog = true;
            this.siteAnalyzeService.downloadRecordPdf(paramsObj).then((data) => {
                const headers = data.headers;
                const fileName = headers['filename'];
                const blob = new Blob([data.data]);
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = decodeURIComponent(fileName);
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                a.remove();
                this.downloadDialog = false;
            });
        },

    },
};
</script>

<style scoped>
@media screen and (max-width: 960px) {
    ::v-deep(.fc-header-toolbar) {
        display: flex;
        flex-wrap: wrap;
    }
}

::v-deep(.fc .fc-col-header-cell-cushion),
::v-deep(.fc-daygrid-dot-event .fc-event-time),
::v-deep(.fc-daygrid-dot-event .fc-event-title),
::v-deep(.fc .fc-daygrid-day-number),
::v-deep(.fc .fc-daygrid-more-link) {
    color: var(--text-color);
}

::v-deep(.fc.fc-theme-standard .fc-highlight) {
    background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));;
}

/*.fc-list-event-title .fc-list-event-title*/
::v-deep(.fc-list-event.fc-event.fc-event-start.fc-event-end) :hover {
    color: black;
}

.folderItem .downloadDiv {
    display: none;
}

.folderItem:hover .downloadDiv {
    display: block;
}
</style>